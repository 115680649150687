import * as React from "react";
import { Button, Form, Input, Select, Switch } from "antd";

const ORDER_API_URL = "https://moorfieldapi.luxedesignscheshire.co.uk/orders"
const BOOK_BAG_PRICE = 650;
const PE_BAG_PRICE = 650;
const BAG_COLOURS = ['Black', 'White', 'Sky Blue', 'Airforce Blue', 'Ocean Blue', 'Surf Blue', 'French Navy', 'Bright Royal', 'Sapphire Blue', 'Purple', 'Bottle Green', 'Kelly Green', 'Lime Green', 'Olive Green', 'Light Grey', 'Silver', 'Graphite Grey', 'Bright Red', 'Classic Red', 'Burgundy', 'Classic Pink', 'Magenta', 'Fuchsia', 'Fluorescent Pink', 'Orange', 'Fluorescent Orange', 'Yellow', 'Gold', 'Fluorescent Yellow', 'Jungle Camo'];

const displayPrice = price => {
  if (price === 0) {
    return "";
  }
  return `(£${(price / 100).toFixed(2)})`;
}

export const OrderForm = () => {
  const [form] = Form.useForm();
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const onProductChange = () => {
    let subTotal = 0;
    const {pebag, bookbag} = form.getFieldsValue();
    if (pebag) {
      subTotal += PE_BAG_PRICE;
    }
    if (bookbag) {
      subTotal += BOOK_BAG_PRICE;
    }
    setTotalPrice(subTotal);
  };
  const finished = formValues => {
    setIsLoading(true);
    fetch(ORDER_API_URL, {
      body: JSON.stringify(formValues),
      method: "POST"
    }).then(res => res.json())
    .then(order => {
      window.location.href = order.url;
    }).catch(() => setIsLoading(false))
  };
  return (
    <Form onFinish={finished} form={form} layout="vertical">
      <Form.Item
        label="Your Name"
        name="name"
        rules={[{ required: true, message: "Please enter your name" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email Address"
        name="email"
        validateTrigger={["onBlur"]}
        rules={[
          { type: "email", required: true, message: "Please enter your email" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Childs Name (Firstname & Lastname)"
        name="childsName"
        rules={[{ required: true, message: "Please enter your childs name" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="PE Bag Colour"
        name="pebag"
        >
          <Select onChange={onProductChange}>
          <Select.Option value=""></Select.Option>
            {BAG_COLOURS.map((colour, index) => (
              <Select.Option value={colour} key={`colour-${index}`}>{colour}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Include Book Bag (Red)" valuePropName="checked" name="bookbag" >
          <Switch onChange={onProductChange} />
        </Form.Item>
        {(form.getFieldValue("bookbag")) && (
          <Form.Item label="Include childs name (Free)" valuePropName="checked" name="bookbag-personalisation" >
            <Switch />
          </Form.Item>
        )}
      <Form.Item wrapperCol={1}>
        <Button type="primary" htmlType="submit" block loading={isLoading} disabled={totalPrice === 0}>
          Place Order {displayPrice(totalPrice)}
        </Button>
      </Form.Item>
    </Form>
  );
};
