import React from "react";
import { OrderForm } from "./components/OrderForm";
import { Row, Col, Typography, Space } from "antd";

class Home extends React.Component {
  render() {
    return (
      
        // <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <>
          <Typography.Title>Moorfield School Order Form</Typography.Title>
          <Typography.Text>Fill out the form below to order your Moorfield School products.</Typography.Text>
          <OrderForm />
        </>
        // </Space>
        
    );
  }
}

export default Home;
